import React from 'react'
import { motion } from 'framer-motion'

const LogoMark = () => (
  <svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L28.4572 16.0011L13.2726 24.7625L3.82427 31.2245L1.82841 28.3365L11.3881 21.7983L21.3674 16.0403L3.52296 6.00666V20.2383L10.745 16.0712L5.46534 13.22L7.14611 10.1399L17.942 15.9701L0 26.3225V0Z" fill="url(#paint0_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L28.4572 16.0011L13.2726 24.7625L3.82427 31.2245L1.82841 28.3365L11.3881 21.7983L21.3674 16.0403L3.52296 6.00666V20.2383L10.745 16.0712L5.46534 13.22L7.14611 10.1399L17.942 15.9701L0 26.3225V0Z" fill="url(#paint1_linear)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0 0L28.4572 16.0011L13.2726 24.7625L3.82427 31.2245L1.82841 28.3365L11.3881 21.7983L21.3674 16.0403L3.52296 6.00666V20.2383L10.745 16.0712L5.46534 13.22L7.14611 10.1399L17.942 15.9701L0 26.3225V0Z" fill="url(#paint2_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-1.89715" y1="6.02578" x2="5.83311" y2="19.1141" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="28.53" y1="14.9544" x2="1.72046e-07" y2="14.9544" gradientUnits="userSpaceOnUse">
    <stop stopColor="white" stopOpacity="0"/>
    <stop offset="1" stopColor="white"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="1.94522" y1="35.432" x2="16.5568" y2="12.0969" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="1" stopColor="white" stopOpacity="0"/>
    </linearGradient>
    </defs>
  </svg>
)

const LogoName = () => (
  <svg width="176" height="34" viewBox="0 0 176 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.1659 23.6585C19.1423 24.6861 17.9268 25.4943 16.5193 26.083C15.1119 26.6716 13.5766 26.9659 11.9132 26.9659C10.2499 26.9659 8.71454 26.6766 7.30712 26.0979C5.90954 25.5192 4.69404 24.7061 3.66061 23.6585C2.62719 22.6108 1.82506 21.3786 1.25421 19.9619C0.683371 18.5351 0.397949 16.9787 0.397949 15.2925C0.397949 13.6063 0.683371 12.0548 1.25421 10.6381C1.82506 9.21131 2.62719 7.97412 3.66061 6.9265C4.69404 5.87888 5.90954 5.06573 7.30712 4.48705C8.71454 3.90836 10.2499 3.61902 11.9132 3.61902C13.5766 3.61902 15.1119 3.91335 16.5193 4.50201C17.9268 5.0807 19.1423 5.88886 20.1659 6.9265L17.0804 10.0544C16.4603 9.30609 15.7074 8.72242 14.8216 8.30337C13.9358 7.87435 12.9663 7.65983 11.9132 7.65983C10.5747 7.65983 9.38381 7.98909 8.34054 8.64759C7.29728 9.30609 6.47546 10.214 5.87509 11.3714C5.27472 12.5188 4.97454 13.8258 4.97454 15.2925C4.97454 16.7591 5.27472 18.0662 5.87509 19.2136C6.47546 20.361 7.29728 21.2689 8.34054 21.9374C9.38381 22.5959 10.5747 22.9251 11.9132 22.9251C12.9663 22.9251 13.9358 22.7156 14.8216 22.2966C15.7074 21.8675 16.4603 21.2789 17.0804 20.5306L20.1659 23.6585Z" fill="white"/>
    <path d="M26.478 3.46936V26.517H22.2262V3.46936H26.478Z" fill="white"/>
    <path d="M28.937 6.46256C28.937 5.74419 29.1535 5.1655 29.5865 4.7265C30.0196 4.2875 30.5904 4.068 31.2991 4.068C32.0077 4.068 32.5785 4.2875 33.0116 4.7265C33.4447 5.1655 33.6612 5.74419 33.6612 6.46256C33.6612 7.18092 33.4447 7.75961 33.0116 8.19861C32.5785 8.63761 32.0077 8.85711 31.2991 8.85711C30.5904 8.85711 30.0196 8.63761 29.5865 8.19861C29.1535 7.75961 28.937 7.18092 28.937 6.46256ZM29.1732 26.517V11.551H33.425V26.517H29.1732Z" fill="white"/>
    <path d="M36.1201 11.551H40.1357L40.2391 13.1673C40.8591 12.5587 41.5825 12.0898 42.4093 11.7605C43.236 11.4213 44.1267 11.2517 45.0814 11.2517C46.4691 11.2517 47.7043 11.5909 48.787 12.2694C49.8696 12.9378 50.7209 13.8607 51.341 15.0381C51.961 16.2054 52.2711 17.5374 52.2711 19.034C52.2711 20.5306 51.961 21.8675 51.341 23.0449C50.7209 24.2122 49.8696 25.1351 48.787 25.8136C47.7043 26.482 46.4691 26.8163 45.0814 26.8163C44.1562 26.8163 43.295 26.6616 42.4978 26.3523C41.7006 26.0331 40.992 25.5941 40.3719 25.0353V34H36.1201V11.551ZM44.1218 14.9932C43.0392 14.9932 42.1435 15.3773 41.4349 16.1455C40.7263 16.9138 40.3719 17.8766 40.3719 19.034C40.3719 20.1913 40.7263 21.1542 41.4349 21.9224C42.1435 22.6907 43.0392 23.0748 44.1218 23.0748C45.2044 23.0748 46.1001 22.6907 46.8087 21.9224C47.5173 21.1542 47.8716 20.1913 47.8716 19.034C47.8716 17.8766 47.5173 16.9138 46.8087 16.1455C46.1001 15.3773 45.2044 14.9932 44.1218 14.9932Z" fill="white"/>
    <path d="M54.169 11.551H58.1846L58.2879 13.1673C58.908 12.5587 59.6314 12.0898 60.4581 11.7605C61.2849 11.4213 62.1756 11.2517 63.1303 11.2517C64.518 11.2517 65.7532 11.5909 66.8358 12.2694C67.9185 12.9378 68.7698 13.8607 69.3899 15.0381C70.0099 16.2054 70.3199 17.5374 70.3199 19.034C70.3199 20.5306 70.0099 21.8675 69.3899 23.0449C68.7698 24.2122 67.9185 25.1351 66.8358 25.8136C65.7532 26.482 64.518 26.8163 63.1303 26.8163C62.2051 26.8163 61.3439 26.6616 60.5467 26.3523C59.7495 26.0331 59.0409 25.5941 58.4208 25.0353V34H54.169V11.551ZM62.1707 14.9932C61.088 14.9932 60.1924 15.3773 59.4838 16.1455C58.7751 16.9138 58.4208 17.8766 58.4208 19.034C58.4208 20.1913 58.7751 21.1542 59.4838 21.9224C60.1924 22.6907 61.088 23.0748 62.1707 23.0748C63.2533 23.0748 64.1489 22.6907 64.8576 21.9224C65.5662 21.1542 65.9205 20.1913 65.9205 19.034C65.9205 17.8766 65.5662 16.9138 64.8576 16.1455C64.1489 15.3773 63.2533 14.9932 62.1707 14.9932Z" fill="white"/>
    <path d="M75.1558 11.551L78.6104 18.8694C78.7678 19.1886 78.9155 19.5528 79.0533 19.9619C79.191 20.3709 79.3091 20.8199 79.4076 21.3088L83.3936 11.551H87.8816L78.6989 34H74.2257L77.5917 25.7687L70.7121 11.551H75.1558Z" fill="white"/>
    <path d="M93.7276 22.2517C94.4855 23.0498 95.3417 23.6385 96.2964 24.0176C97.2511 24.3868 98.191 24.5714 99.1162 24.5714C100.012 24.5714 100.839 24.4118 101.596 24.0925C102.364 23.7732 102.979 23.3192 103.442 22.7306C103.904 22.1319 104.136 21.4186 104.136 20.5904C104.136 19.7424 103.895 19.034 103.412 18.4653C102.93 17.8966 102.3 17.4027 101.523 16.9836C100.755 16.5546 99.938 16.1455 99.0719 15.7564C98.3534 15.4272 97.6399 15.083 96.9313 14.7238C96.2226 14.3546 95.578 13.9356 94.9973 13.4666C94.4264 12.9877 93.9688 12.424 93.6243 11.7755C93.2798 11.127 93.1076 10.3487 93.1076 9.44079C93.1076 8.22355 93.4225 7.18092 94.0524 6.3129C94.6823 5.44487 95.5287 4.78138 96.5917 4.32242C97.6645 3.85349 98.8554 3.61902 100.164 3.61902C101.03 3.61902 101.931 3.72877 102.866 3.94827C103.801 4.1578 104.731 4.49204 105.656 4.95099L104.608 7.19589C103.919 6.78682 103.206 6.4875 102.467 6.29793C101.729 6.10836 101.011 6.01358 100.312 6.01358C99.0621 6.01358 97.9893 6.30292 97.0936 6.8816C96.2079 7.46029 95.765 8.28841 95.765 9.36596C95.765 10.1242 95.9913 10.7628 96.4441 11.2816C96.8968 11.8004 97.4873 12.2544 98.2156 12.6435C98.944 13.0326 99.7215 13.4167 100.548 13.7959C101.286 14.1351 102.025 14.4943 102.763 14.8734C103.501 15.2526 104.175 15.6916 104.785 16.1904C105.395 16.6893 105.883 17.283 106.247 17.9714C106.621 18.6499 106.808 19.463 106.808 20.4108C106.808 21.668 106.468 22.7904 105.789 23.7782C105.12 24.7659 104.205 25.5442 103.043 26.1129C101.882 26.6816 100.563 26.9659 99.0867 26.9659C97.945 26.9659 96.7984 26.7714 95.6469 26.3823C94.4953 25.9932 93.4225 25.3895 92.4285 24.5714L93.7276 22.2517Z" fill="white"/>
    <path d="M110.699 11.551V7.80949H113.135V11.551H117.15V13.7959H113.135V21.8177C113.135 23.6535 113.952 24.5714 115.585 24.5714C115.841 24.5714 116.102 24.5514 116.368 24.5115C116.643 24.4616 116.904 24.3768 117.15 24.2571V26.4122C116.894 26.522 116.589 26.6167 116.235 26.6966C115.891 26.7764 115.487 26.8163 115.024 26.8163C113.627 26.8163 112.554 26.4022 111.806 25.5741C111.068 24.746 110.699 23.5637 110.699 22.0272V13.7959H108.263V11.551H110.699Z" fill="white"/>
    <path d="M122.518 11.551V19.6925C122.518 21.1392 122.901 22.3165 123.669 23.2245C124.447 24.1224 125.456 24.5714 126.696 24.5714C127.532 24.5714 128.28 24.3669 128.94 23.9578C129.599 23.5387 130.121 22.975 130.505 22.2666C130.888 21.5483 131.08 20.7401 131.08 19.8421V11.551H133.516V26.517H131.316L131.184 24.2421C130.613 25.0503 129.899 25.6839 129.043 26.1428C128.197 26.5918 127.252 26.8163 126.208 26.8163C125.018 26.8163 123.96 26.5269 123.034 25.9483C122.119 25.3596 121.396 24.5514 120.864 23.5238C120.343 22.4961 120.082 21.3188 120.082 19.9918V11.551H122.518Z" fill="white"/>
    <path d="M143.755 11.2517C144.838 11.2517 145.842 11.4512 146.767 11.8503C147.692 12.2494 148.484 12.8031 149.144 13.5115V4.068H151.58V26.517H149.38L149.247 24.4367C148.588 25.175 147.786 25.7587 146.841 26.1877C145.896 26.6068 144.868 26.8163 143.755 26.8163C142.328 26.8163 141.049 26.482 139.917 25.8136C138.795 25.1351 137.909 24.2122 137.26 23.0449C136.62 21.8675 136.3 20.5306 136.3 19.034C136.3 17.5374 136.62 16.2054 137.26 15.0381C137.909 13.8607 138.795 12.9378 139.917 12.2694C141.049 11.5909 142.328 11.2517 143.755 11.2517ZM138.81 19.034C138.81 20.0916 139.031 21.0394 139.474 21.8775C139.927 22.7156 140.542 23.3741 141.319 23.853C142.097 24.3319 142.983 24.5714 143.977 24.5714C144.971 24.5714 145.857 24.3319 146.634 23.853C147.412 23.3741 148.022 22.7156 148.465 21.8775C148.918 21.0394 149.144 20.0916 149.144 19.034C149.144 17.9664 148.918 17.0186 148.465 16.1904C148.022 15.3523 147.412 14.6938 146.634 14.2149C145.857 13.736 144.971 13.4966 143.977 13.4966C142.983 13.4966 142.097 13.736 141.319 14.2149C140.542 14.6938 139.927 15.3523 139.474 16.1904C139.031 17.0186 138.81 17.9664 138.81 19.034Z" fill="white"/>
    <path d="M154.939 5.78909C154.939 5.29022 155.097 4.88115 155.412 4.56188C155.737 4.23263 156.14 4.068 156.622 4.068C157.114 4.068 157.523 4.23263 157.848 4.56188C158.173 4.88115 158.335 5.29022 158.335 5.78909C158.335 6.27798 158.173 6.68705 157.848 7.0163C157.523 7.34555 157.114 7.51017 156.622 7.51017C156.14 7.51017 155.737 7.34555 155.412 7.0163C155.097 6.68705 154.939 6.27798 154.939 5.78909ZM155.412 26.517V11.551H157.862V26.517H155.412Z" fill="white"/>
    <path d="M160.646 19.034C160.646 17.5374 160.976 16.2054 161.635 15.0381C162.305 13.8607 163.215 12.9378 164.367 12.2694C165.528 11.5909 166.847 11.2517 168.323 11.2517C169.799 11.2517 171.113 11.5909 172.265 12.2694C173.426 12.9378 174.337 13.8607 174.996 15.0381C175.665 16.2054 176 17.5374 176 19.034C176 20.5306 175.665 21.8675 174.996 23.0449C174.337 24.2122 173.426 25.1351 172.265 25.8136C171.113 26.482 169.799 26.8163 168.323 26.8163C166.847 26.8163 165.528 26.482 164.367 25.8136C163.215 25.1351 162.305 24.2122 161.635 23.0449C160.976 21.8675 160.646 20.5306 160.646 19.034ZM163.156 19.034C163.156 20.0916 163.377 21.0394 163.82 21.8775C164.273 22.7156 164.888 23.3741 165.666 23.853C166.443 24.3319 167.329 24.5714 168.323 24.5714C169.317 24.5714 170.203 24.3319 170.98 23.853C171.758 23.3741 172.368 22.7156 172.811 21.8775C173.264 21.0394 173.49 20.0916 173.49 19.034C173.49 17.9664 173.264 17.0186 172.811 16.1904C172.368 15.3523 171.758 14.6938 170.98 14.2149C170.203 13.736 169.317 13.4966 168.323 13.4966C167.329 13.4966 166.443 13.736 165.666 14.2149C164.888 14.6938 164.273 15.3523 163.82 16.1904C163.377 17.0186 163.156 17.9664 163.156 19.034Z" fill="white"/>
  </svg>
)

const Logo = () => {
  const markVariants = {
    hidden: {
      rotate: -90,
      opacity: 0
    },
    visible: {
      rotate: 0,
      opacity: 1,
      transition: {
        delay: .2,
        duration: .35
      }
    }
  }
  
  const nameVariants = {
    hidden: {
      x: -50,
      opacity: 0
    },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        delay: .45
      }
    }
  }

  return (
    <div className={`mt-1`}>
      <motion.div
        variants={markVariants}
        initial={`hidden`}
        animate={`visible`}
        style={{ display: `inline-block`, marginRight: 6 }}
      >
        <LogoMark />
      </motion.div>
      <motion.div
        variants={nameVariants}
        initial={`hidden`}
        animate={`visible`}
        style={{ display: `inline-block` }}
      >
        <LogoName />
      </motion.div>
    </div>
  )
}

export default Logo
 