import React from 'react'
import style from './stylesheets/Hero.module.css'
import Logo from './Logo'
import {motion, useViewportScroll, useTransform, useSpring, AnimatePresence} from 'framer-motion'
import video from '../../static/clippy-trailer.mp4'
import poster from '../../static/video-poster.png'

const Video = () => {

  const [ played, setPlayed ] = React.useState(false)

  // const { scrollYProgress } = useViewportScroll()
  // const inputRange= [0.5, 0.75]
  // const animation = {
  //   y: useSpring(useTransform(scrollYProgress, inputRange, [-150, 0]), {stiffness: 400, damping: 90}),
  //   scale: useSpring(useTransform(scrollYProgress, inputRange, [0.7, 1]), {stiffness: 400, damping: 90})
  // }
  // Scroll animation for the video

  const videoPlayer = React.useRef()

  const handlePlay = () => {
    videoPlayer.current.play()
    setPlayed(true)
  }

  const handleEnded = () => {
    setPlayed(false)
  }

  const playVariants = {
    visible: {
      opacity: 1,
      scale: 1
    },
    exit: {
      opacity: 0,
      scale: 0
    }
  }

  return (
    <motion.div className={style.video}
    
    >
      {/* y={animation.y} scale={animation.scale} */}
      <div className={style.videoContent}>
        <AnimatePresence>
          { !played && (
            <motion.div
              onClick={handlePlay}
              className={style.playButton}
              variants={playVariants}
              initial={`exit`}
              animate={`visible`}
              exit={`exit`}
            >
              <div>
                
              <svg version="1.1" viewBox="0 0 72.6 72.6">
                <path class="st1" d="M20.4,13.2l41,23.1L39.5,48.9l-13.6,9.3L23.1,54l13.8-9.4l14.4-8.3L25.5,21.9v20.5l10.4-6l-7.6-4.1l2.4-4.4
		l15.6,8.4L20.4,51.1V13.2z" fill="white" />
              </svg>




              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <video controls={played} ref={videoPlayer} onEnded={handleEnded} poster={poster}>
          <source src={video} type={`video/mp4`} />
        </video>
      </div>
    </motion.div>
  )
}

const Hero = () => {

  const variants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 1
      }
    }
  }

  return (
    <section className={style.hero}>
      <motion.div
        className={`container`}
        style={{ position: `relative`, zIndex: 1 }}
        initial={`hidden`}
        animate={`visible`}
        variants={variants}
      >

        <div className={`row`}>
          <div className={`col-12`}>
            <Logo />
          </div>
        </div>

        <div className={`row mb-2 mt-4`} style={{alignItems: `flex-end`}}>
          <motion.div
            className={`col-md-6`}
          >
            <h1>Superb Business Clips Made Simple.</h1>
          </motion.div>
          <motion.div
            className={`col-md-6 mt-1`}
          >
          <p>Create stunning, short-form videos to share your ideas and products on all your business' social media profiles.</p>
          </motion.div>
        </div>

        <div className={`row mb-3`}>
          <div className={`col-12`} style={{ textAlign: 'center' }}>
            <motion.a whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.05 }} href={`//app.websitebuilder.latest.wzdev.co/studio/`} className={style.cta}>Get Started</motion.a>
          </div>
        </div>

        <div className={`row mb-4`}>
          <motion.div className={`col-md-8 col-sm-10 col-12 mx-auto`}>
            <Video />
          </motion.div>
        </div>

      </motion.div>
    </section>
  )
}

export default Hero
