import React from 'react'
import SEO from '../components/SEO'
import Hero from '../components/Hero'

const Home = () => (
  <>
    <SEO />
    <Hero />
    <div className={`container`}>
      <div className={`row`} style={{ textAlign: `center` }}>
        <div className={'col-sm-4 mb-4'}>
          <h3 style={{ color: `#282EB5` }} className={`mb-1`}>Create a Video</h3>
          <p>Start a video from scratch or pick one of our beautiful templates for a great starting point.</p>
        </div>
        <div className={'col-sm-4 mb-4'}>
          <h3 style={{ color: `#2367C5` }} className={`mb-1`}>Add Your Content</h3>
          <p>Mix your own photos and videos with free stock footage, and even add your store's products or blog posts.</p>
        </div>
        <div className={'col-sm-4 mb-4'}>
          <h3 style={{ color: `#1F9FD5` }} className={`mb-1`}>Share Online</h3>
          <p>Export your videos for use on your website and all your social media accounts.</p>
        </div>
      </div>
    </div>
  </>
)

export default Home
